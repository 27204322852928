<template>
    <div class="box">
         <header>
        <div class="logoBox">
          <img src="../assets/logo-white.png" alt="" />
        </div>
      <div class="title">
        <p class="title-cn">现场安全生产实时管理平台</p>
        <span class="title-en">
          On-site safety production real time management platform</span
        >
      </div>
    </header>
        <div class="login-box">
            <h1>用户登录</h1>
            <div class="username">
                <img src="../assets/user.png" class="title-pg" alt="">
                <input type="text" v-model="username">
            </div>
            <div class="password"> 
                <img src="../assets/pwd.png" class="title-pg" alt="">
                <input type="password" v-model="password">
            </div>
            <div class="login-bth" @click="turnToHome">登录</div>
        </div>
        <div></div>
    </div>
</template>
<script>
import {login} from '../api/index'
export default {
    data() {
        return {
            username:"",
            password:"",
        }
    },
    methods:{
        async turnToHome(){
            if(this.username && this.password){
             let res = await login({phone:this.username,code:this.password})
             console.log(res)
             if(res.data.msg=='success'){
                 this.$message({
                     message:"登录成功",
                     type:"success"
                 })
                 let {token,username,data}={...res.data};
                 sessionStorage.setItem('token',token);
                 sessionStorage.setItem('username',username);
                  sessionStorage.setItem('role',data);
                 this.$store.commit("setUserName",username)
                  this.$store.commit("setUserRole",data)
                  this.$router.push('/home')
             }else{
               this.$message.error(res.data.msg)
             }
            }else{
                 this.$message.error('账号密码有误，请重新确认');
            }
        }
    }
}
</script>
<style scoped>
.box{
    width: 1920px;
    height: 1080px;
    background-image: url('../assets/bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
}
.login-box{
position: absolute;
width: 499px;
height: 484px;
box-sizing: border-box;
background-image: url('../assets/login-bg.png');
background-size: 100% 100%;
background-repeat: no-repeat;
left: 1215px;
top: 290px;
text-align: center;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
padding: 35px 0 ;
}
.login-box h1{
    font-size: 38px;
font-family: 'Alibaba PuHuiTi';
font-weight: 400;
color: #FFFFFF;
margin: 0;
}
.username,
.password{
    width: 403px;
    height: 56px;
    position: relative;
}
.login-box input{
   width: 100%;
   height: 100%;
   color: #FFFFFF;
   background-color: transparent;
   font-size: 24px;
   box-sizing: border-box;
   padding-left: 48px;
   border: 0;
   background-image: url('../assets/input-bg.png');
   background-size: 100% 100%; 
   background-repeat: no-repeat;
}
.login-bth{
    width: 403px;
height: 56px;
background: linear-gradient(0deg, #0177E4, #00B8FF);
border-radius: 6px;
font-size: 26px;
line-height: 56px;
font-family: 'Alibaba PuHuiTi';
font-weight: 400;
color: #FFFFFF;
cursor: pointer;
}
input[type=text]:focus{
    outline: transparent;
}
input[type=password]:focus{
    outline: transparent;
}
.title-pg{
    position: absolute;
    top: 18px;
    left: 18px;
}
header {
    position: absolute;
  width: calc(100% - 80px);
 top: 40px;
 left: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.logoBox {
  width: 139px;
  height: 58px;
  overflow: hidden;
  margin-right: 30px;
}
.logoBox img {
  width: 100%;
  height: 100%;
}
.title {
  text-align: center;
  padding-left: 30px;
  border-left: 2px solid #fff;
}
.title-cn {
  font-size: 40px;
  font-family: " Alibaba PuHuiTi";
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}
.title-en {
  font-size: 22px;
  font-family: "Alibaba PuHuiTi";
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
}
</style>